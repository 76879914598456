import _ from 'lodash';
import React from 'react'
import Dollar from '../assets/dollar-sign.png';
import Marketing from '../assets/marketing.jpg';
import parse from 'html-react-parser';

const MicroBusiness = ({ EXPLORE_TEXTS }) => {

    // const COLOR = { bonuspool: "text-button-color", sponsor: "text-button-color" };

    function InnerArray(arrayElemts) {
        return _.map(arrayElemts, (row) => ({ section: row, image: Dollar, heading: EXPLORE_TEXTS?.[row + '_text'], para: EXPLORE_TEXTS?.[row + '_para'] }))
    }

    const FIGURE_ARRAY = [
        { section: "first_row", InnerArray: InnerArray(['retail', 'sponsor', 'team', 'generation']) },
        { section: "second_row", InnerArray: InnerArray(['additionl_reward']) },
        { section: "third_row", InnerArray: InnerArray(['rank', 'lifestyle', 'bonuspool', 'two_product_incentive']) }
    ];

    return (
        <>
            <section className="py-[100px]">
                <div className="container mx-auto max-w-[1260px] px-[15px]">
                    <div className="-mx-[15px] flex flex-wrap">
                        <div className="px-[15px] mb-[60px] w-full">
                            <h2 className='text-[32px] font-semibold leading-[1.2] text-heading-color text-center'>{EXPLORE_TEXTS?.provizion_plan_fusion_para}
                            </h2>
                        </div>
                        {_.map([
                            { title: EXPLORE_TEXTS?.microbusiness_text, para: EXPLORE_TEXTS?.microbusiness_utilizes_para },
                            { title: EXPLORE_TEXTS?.network_marketing_text, para: EXPLORE_TEXTS?.network_marketing_para },
                        ], ({ title, para }) => (
                            <div className="px-[15px] md:w-1/2 w-full" key={title}>
                                <p className='text-[20px] leading-[1.6] mb-3 text-heading-color'><strong className="font-semibold">{title}              </strong>
                                    {para}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="pt-[80px] pb-[76px]">
                <div className="container mx-auto max-w-[1260px] px-[15px]">
                    <div className="-mx-[15px] flex flex-wrap">
                        {_.map(FIGURE_ARRAY, (row) => (
                            <div className="px-[15px] md:w-2/6 w-full" key={row?.section}>
                                {(row?.section === "second_row") &&
                                    <figure className="text-center mb-5 f-full border-4 border-black">
                                        <img src={Marketing} alt="Dollar" className='w-full h-full object-contain block' />
                                    </figure>
                                }
                                {_.map(row?.InnerArray, ({ heading, image, para, section }, index) => (
                                    <div className="text-center mb-[30px]" key={index + 1}>
                                        <figure className='w-[40px] h-[40px] mb-5 mx-auto'>
                                            <img src={image} alt="Dollar" className='w-full h-full object-contain block' />
                                        </figure>
                                        <h5 className={`text-[25px] leading-[1.2] mb-3 uppercase font-semibold`}>{heading}</h5>
                                        <p className='text-[16px] leading-[1.5] font-normal mb-4'>{para}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="text-center mt-[44px]">
                        <a href={"/api/Files/documents/CompensationPlan.pdf"} target='_blank' className='inline-block py-[15px] px-[40px] text-[19px] font-medium bg-[#DEAA17]  border-[1px] text-white transition-all ease-in-out duration-300 hover:border-[#C5C5C5]'>DOWNLOAD PDF <span><i className="fa-solid fa-file-pdf"></i></span></a>
                    </div>
                </div>
            </section>

            <section className="pt-[100px] pb-[120px] bg-[#F4F4F4]">
                <div className="container mx-auto max-w-[1260px] px-[15px]">
                    <div className="-mx-[15px] flex flex-wrap">
                        <div className="px-15px mb-[60px] w-full">
                            <h2 className='text-[32px] font-semibold leading-[1.2] text-heading-color text-center'>{EXPLORE_TEXTS?.four_foury_four_heading}</h2>
                        </div>
                        <div className="px-[15px] md:w-1/2 w-full">
                            <p className='text-[20px] leading-[1.6] mb-3 text-heading-color'>{EXPLORE_TEXTS?.four_foury_four_first_para}</p>
                        </div>
                        <div className="px-[15px] md:w-1/2 w-full">
                            <p className='text-[20px] leading-[1.6] mb-3 text-heading-color'>{parse(EXPLORE_TEXTS?.four_foury_four_second_para)}</p>
                        </div>
                        <div className="px-[15px] w-full mt-9">
                            <p className='text-[20px] leading-[1.6] mb-3 text-heading-color'>{EXPLORE_TEXTS?.four_foury_four_third_para}</p>
                        </div>
                        <div className="px-[15px] w-full text-center block mt-[60px]">
                            <a href={'/api/Files/documents/MicroBusinessPlan.pdf'} target='_blank' className='inline-block py-[15px] px-[40px] text-[19px] font-medium bg-[#DEAA17] border-[1px] text-white transition-all ease-in-out duration-300 hover:border-[#C5C5C5]'>{EXPLORE_TEXTS?.download_m_plan}</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default MicroBusiness