import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import localStorageCall from '../../../services/Methods/localstorage.hook';
import { storeAssigned } from '../../../services/Methods/normalMethods';
import { AuthenticateProfileCode, customerUpdateApi, SignUpFormApi } from '../../../services/Redux/Reducer/LoginSlice';
import ROUTING_TEXT from '../../../TextConfigs/RoutingAndSmallContent.text';

const useRetailSignupHook = (props) => {
    const FormId = 'retailer_steps';
    const navigate = useNavigate();
    const {
        //states
        allProductState, activeStep, setActiveStep, formData, setFormData, countryState, setCountryState, setOpenMiniCart,
        webAliesError, setWebAliesErros, otp, emailExistError, isUserLogin, addToCartProducts, setAddToCartProduct,

        //functions
        dispatch, handleNext, handleBack, handleInputChange, _handleSetFormValues, inCreaseStepper, _validateRefferalFunction, _emailValidate,
        _handleOtpValidate, handleChangeValidate, _addToCartFunction,
        _getPorductAccordingSection
    } = props;

    const memoRizedSelector = createSelector(
        state => state?.LoginSlice,
        (loginState) => ({
            isLoginLoading: loginState?.loading,
            validateCustomerToken: loginState?.validateCustomerToken,
        })
    )
    const { isLoginLoading, validateCustomerToken, productLoading } = useSelector((state) => memoRizedSelector(state));

    function _handleSubmit(values, actions) {
        if (webAliesError?.changeRefferal && !webAliesError?.isRefferalValid) {
            setWebAliesErros((prv) => ({ ...prv, isRefferalError: "Please verify your referrer info", isRefferalValid: false, isRefferalId: null }));
            return;
        }
        _handleSetFormValues(values, async (CHANGED_DATA) => {
            if (isUserLogin) {
                dispatch(customerUpdateApi({ ...CHANGED_DATA, refferal: webAliesError?.isRefferalId }, 'retail', isUserLogin, (data) => {
                    _checkProduct(() => inCreaseStepper(data));
                }));
            } else {
                _emailValidate(CHANGED_DATA?.email, () => {
                    dispatch(SignUpFormApi({ ...CHANGED_DATA, refferal: webAliesError?.isRefferalId }, 'retail', (data) => {
                        _checkProduct(() => inCreaseStepper(data))
                    }))
                })
                actions.setSubmitting(false);
            }
        })
    }


    function _checkProduct(callback) {
        let cartValues = localStorageCall().getItem('normalProducts');
        if (cartValues === null)
            navigate(`/${storeAssigned()}`)
        else
            callback();
    }

    function _handleOtpSubmit() {
        _handleOtpValidate((status) => {
            if (status) {
                dispatch(AuthenticateProfileCode(isUserLogin, validateCustomerToken, otp?.join(''), () => {
                    _checkProduct(() => setActiveStep(activeStep + 1))
                }));
            }
        })
    }

    return ({
        //states && variables
        FormId, activeStep, setActiveStep, otp, formData, setFormData, countryState, setCountryState, webAliesError, setWebAliesErros,
        productList: allProductState, emailExistError, addToCartProducts, setAddToCartProduct, setOpenMiniCart, isUserLogin,

        //functions
        handleInputChange, _handleSubmit, handleChangeValidate, _handleOtpSubmit, isLoginLoading: isLoginLoading || productLoading,
        _addToCartFunction, _validateRefferalFunction,
    });
}

export default useRetailSignupHook;